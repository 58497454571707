.openPopupbtn {
    margin: 95px 0 75px 0;
    width: 80%;
    padding: 20px 0;
    border: #7e5531 1px solid;
    border-radius: 16px;
    color: #463830;
    background-color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.popup-content {
    background-color: white;
    width: 50%;
    /* max-width: 400px; */
    margin: 20% auto;
    padding: 20px;
    border-radius: 10px;
}

.close {
    float: right;
    font-size: 30px;
    line-height: 15px;
    cursor: pointer;
}

h2 {
    display: flex;
    margin: 0 10vw 0 10vw;
    color: #7e5531;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
}

@media only screen 
and (max-device-width : 1000px) {
    .openPopupbtn {
        margin: 30px 0 30px 0;
        font-size: 16px;
        line-height: 18px;
    }

    h2 {
        text-align: center;
        font-size: 18px;
        font-weight: 400;
    }
    
    .order {
        margin: 30px 0 10px 0;
    }
}