.delivery {
    padding: 40px 0;
    background-color: #ede6e6;
}

.delivery-blocks {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px 50px 0 50px;
}

.delivery-block {
    color: #3a2b1e;
    background-color: #D9D9D9;
    border-radius: 5px;
    padding: 0 20px 20px 20px;
}

@media only screen 
and (max-device-width : 1000px) {
    .delivery-blocks {
        flex-direction: column;
        padding: 50px 50px 0 50px;
    }

    .delivery-block {
        margin: 0 0 20px 0;
    }
}