@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html, body, #root {
	height: 100%;
}

body { 
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
}
