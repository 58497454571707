.technology {
    display: flex;
    flex-direction: row;

    margin: 96px 10vw;
}

.technology .description {
    margin: 30px 0;
    padding: 0 10px;

    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #463830;
}

.technology .container:first-child .description {
    margin-top: -50px;
}

.technology .photo {
    background-color: #D9D9D9;
    margin: 0 auto;
}

.technology .container:first-child .photo {
    /* margin-bottom: 150px; */
    margin-top: 30px;
}

.technology .container:last-child .photo {
    /* margin-bottom: 30px; */
    margin-top: 40px;
}

.technology .subphoto {
    position: relative;
    top: -13%;
    left: 50%;
    
    background-color: #EDE6E6;
}

.technology .photo {
    border-radius: 50%;
    height: 280px;
    width: 280px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
}

.technology .subphoto, .technology .subphoto img {
    border-radius: 50%;
    height: 200px;
    width: 200px;
}

.photo0 {
    background-image: url("../img/IMG_4693.JPG");
}

.photo1 {
    background-image: url("../img/IMG_4694.JPG");   
}

@media only screen 
and (max-device-width : 1000px) { 
    .technology {
        display: flex;
        flex-direction: row;
    
        margin: 0px 5vw;
    }

    .technology .description {
        margin: 30px 0;
    
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
    }

    .technology .container:last-child {
        margin-left: 20px;
    }
    
    .technology .photo {
        height: 150px;
        width: 150px;
    }
    
    .technology .subphoto {
        height: 90px;
        width: 90px;

        top: -7%;
        left: 50%;
    }

    .technology .container:first-child .description {
        margin-top: -20px;
    }

    @media only screen 
    and (max-device-width : 402px) {
        .technology .photo {
            height: 135px;
            width: 135px;
        }
        
        .technology .subphoto {
            height: 80px;
            width: 80px;
    
            top: -7%;
            left: 50%;
        }    
    }
}