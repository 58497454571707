.our-works {
    background-color: #ede6e6;
}

h1 {
    display: flex;
    margin: 0 15vw 0 10vw;
    color: #7e5531;
    font-family: 'Montserrat', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
}

.slider-image {
    width: 260px;
    height: 400px;  
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

    margin: 0 auto;
    margin-bottom: 15px;
}

.slider-span {
    font-size: 20px;
    line-height: 20px;
    text-overflow: clip;
    color: #7e5531;
}

.slide-container {
    padding: 72px 50px 0 50px;
}

.pictures {
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.our-works, .about {
    padding: 5vh 0;
}

.name {
    font-size: 20px;
}

.picture {
    margin: 0 34px 0 34px;
}

.our-works .picture .image img {
    width: 134px;
    height: 207px;
    border-radius: 8px;
}

.picture .name {
    margin: 20px 0 15px 0;
}

.about .about-content {
    display: flex;
    align-items: center;    
    margin: 31px 10vw 0 10vw;
}

.about .description {
    flex: 50%;
}

.about .text {
    text-align: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
}

.about .logo {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-image: url("../img/logo/logo.jpg");
    background-size: cover;
    margin: 0 0 0 90px;
    height: 360px;
    width: 360px;
}

.team {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin: 115px 0 0 0;
}

.team .picture .image img {
    border-radius: 50%;
    height: 220px;
    width: 220px;
}

@media only screen 
and (max-device-width : 1000px) { 
    .mobile-hidden {
        display: none !important;
    }

    h1 {
        display: flex;
        margin: 0 1vw 0 10vw;
        color: #7e5531;
        font-family: 'Montserrat', sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
    }

    .slider-span {
        font-size: 14px;
        line-height: 16px;
        text-overflow: clip;
        color: #7e5531;
    }

    .slide-container {
        padding-top: 40px;
    }
    
    .slider-image {
        width: 150px;
        height: 247px;
    }

    .about .about-content .text {
        font-size: 14px;
        line-height: 20px;

        font-family: 'Montserrat', sans-serif;
    }

    .about .text {
        margin: 15px 0 15px 0;
    }

    .about-content {
        position: relative;
        padding-bottom: 40px;
    }

    .about-content::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px; /* Adjust the height as needed */
        background-color: #7e5531;
    }

    .team {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;

        font-size: 14px;
        margin: 30px 25px 0 25px;
    }

    .team .picture {
        display: flex;
        align-items: center;
    }

    .team {
        position: relative;
        padding-bottom: 20px;
    }

    .team::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px; /* Adjust the height as needed */
        background-color: #7e5531;
    }

    .team .text-content {
        margin: 0 8% 0 8%;
    }
    
    .picture {
        margin: 0;
        width: 100%;
    }

    .team .picture .image img {
        height: 150px;
        width: 150px;
    }    

    .picture .name {
        font-size: 16px;
        margin: 0 0 15px 0;
    }

    @media only screen 
    and (max-device-width : 402px) {
        .slider-image {
            width: 120px;
            height: 210px;
        }

        .slide-container {
            padding: 55px 35px 0 35px;
        }
    }
} 
