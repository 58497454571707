.contacts {
    padding: 5vh 0;
}

.contacts-blocks {
    display: flex;
    flex-direction: column;
    margin: 30px 10vw 0 10vw;
}

.contact {
    display: flex;
    flex-direction: row;
    padding: 15px 0 0 0;
}

.contact img {
    height: 60px;
    width: 60px;
}

.contact a {
    display: flex;
    margin-left: 7vw;
    align-items: center;
    color: #463830;
}

@media only screen 
and (max-device-width : 1000px) { 
    .contacts-blocks {
        display: flex;
        flex-direction: column;
        margin: 20px 10vw 0 10vw;
    }
    
    .contact img {
        height: 40px;
        width: 40px;
    }

    .contact a {
        display: flex;
        margin-left: 7vw;
        text-align: left;
        color: #463830;

        font-size: 14px;
        width: 100%;
    }
}