.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2% 13vw 0.5% 13vw;
}

.button {
    font-size: 28px;
    background-color: rgb(255, 255, 255);
    color: #7e5531;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    /* background-color: #777; */
    color: #a5764d;
}

.button::before {
    /* margin: 0 13vw; */
    content: '';
    position: absolute;
    top: 20px;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 72.5%;
    height: 2px;
    background-color: #7e5531;
}

.bg-image img {
    margin: 0 0 20vh 0;
    width: calc(80%);
    /* padding: 16px; */
    height: calc(85vh);
    object-fit: contain;
}

.logo-head {
    position: absolute;
    top: 29vh;
    left: 13vw;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    background-image: url("../img/logo/logo.jpg");
    background-size: cover;
    height: 300px;
    width: 300px;
}

@media only screen 
and (max-device-width : 1000px) { 
    .header {        
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10vh 13vw 0.5% 13vw;
    }

    .button {
        font-size: 14px;
        background-color: rgb(255, 255, 255);
        color: #7e5531;
        padding: 8px 8px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }    
    
    .button::before {
        /* margin: 0 13vw; */
        content: '';
        position: absolute;
        top: 9vh;
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        width: 72.5%;
        height: 2px;
        background-color: #7e5531;
    }
    
    .button::after {
        /* margin: 0 13vw; */
        content: '';
        position: absolute;
        top: calc(12vh + 16px + 12px);
        left: 50%;
        right: 50%;
        transform: translateX(-50%);
        width: 72.5%;
        height: 2px;
        background-color: #7e5531;
    }

    .bg-image img {
        margin: 5vh 0 8vh 0;
        /* background: url(../img/mainPage.jpg) no-repeat fixed; */
        width: calc(75%);
        /* padding: 16px; */
        height: calc(20vh);
        /* object-fit: contain; */
    }

    .logo-head {
        position: absolute;
        top: 20vh;
        left: 20vw;

        height: 130px;
        width: 130px;
    }

    @media only screen 
    and (max-device-width : 455px) {
        .button::before {
            top: 10vh;
        }

        .button::after {
            top: calc(13vh + 16px + 12px);
        }

        .logo-head {
            position: absolute;
            top: 23vh;
            left: 13vw;
    
            height: 120px;
            width: 120px;
        }
    } 
}
